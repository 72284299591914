
import 'swiper/css';
import { useFundStore } from "../../../scripts/Fund";
import { BigNumber } from "ethers";
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { PoolsType, setAvailableTickets, setTotalTickets } from '../../../app/slices/Rede.slice';
import Loader from '../../../helpers/Loader';
import { memo, useState } from 'react';
// import { IHistory, ITournamentsActiveAndHistory } from "../../"
import { Modal } from 'flowbite-react';
import Wallet from '../../header/wallet/Wallet';
import ButtonLoading from '../../../helpers/ButtonLoading';
import { useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';




const Pac10000 = memo(() => {
  const toast = useToast()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const myStore = new useFundStore();
  const [isLoading, setIsLoading] = useState<boolean>(true)
  myStore.getAllLeftTickets().then((localAllLeftTickets) => {
    dispatch(setAvailableTickets(localAllLeftTickets))
    myStore.getTotalTickets().then(responce => {
      setIsLoading(false)
      dispatch(setTotalTickets(responce))
    })
  })

  const [buyLoading, setBuyLoading] = useState<boolean>(false)
  const [sellLoading, setSellLoading] = useState<boolean>(false)

  const [currentPool, setCurrentPool] = useState<PoolsType>()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const { total_tickets, available_tickets, all_user_tickets, pools, poolStatus, maxTicketInPool } = useAppSelector(state => state.RedeSlice)
  const Theme = {
    "root": {
      "base": "fixed top-0 right-0 left-0 z-50 h-modal h-screen overflow-y-auto overflow-x-hidden md:inset-0 md:h-full",
      "show": {
        "on": "flex bg-gray-900 bg-opacity-50 dark:bg-opacity-80",
        "off": "hidden"
      },
      "sizes": {
        "sm": "max-w-sm",
        "md": "max-w-md",
        "lg": "max-w-lg",
        "xl": "max-w-xl",
        "2xl": "max-w-2xl",
        "3xl": "max-w-3xl",
        "4xl": "max-w-4xl",
        "5xl": "max-w-5xl",
        "6xl": "max-w-6xl",
        "7xl": "max-w-7xl"
      },
      "positions": {
        "top-left": "items-start justify-start",
        "top-center": "items-start justify-center",
        "top-right": "items-start justify-end",
        "center-left": "items-center justify-start",
        "center": "items-center justify-center",
        "center-right": "items-center justify-end",
        "bottom-right": "items-end justify-end",
        "bottom-center": "items-end justify-center",
        "bottom-left": "items-end justify-start"
      }
    },
    "content": {
      "base": "relative h-full w-full p-4 h-auto",
      "inner": "relative rounded-3xl bg-white shadow dark:bg-gray-700 flex flex-col max-h-[90vh]"
    },
    "body": {
      "base": "lg:md:p-6 p-4 pt-0 flex-1 overflow-auto justify-center",
      "popup": ""
    },
    "header": {
      "base": "flex items-start justify-between rounded-t-2xl dark:border-gray-600 border-b",
      "popup": "lg:md:p-6 p-4 border-b-0 pb-0",
      "title": "mb-5 lg:md:text-[32px] text-2xl font-orbitron  text-yellow font-bold text-center",
      "close": {
        "base": "ml-auto inline-flex items-center rounded-lg bg-transparent text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white",
        "icon": "h-5 w-5 text-white"
      }
    },
    "footer": {
      "base": "flex items-center space-x-2 rounded-b-2xl border-gray-200 lg:md:p-6 p-4 pt-0 dark:border-gray-600",
      "popup": "border-t"
    }
  }


  const handleBuy = async (currentPool: PoolsType) => {
    setBuyLoading(true)
    try {
      let promise = new Promise(async (resolve, reject) => {
        await myStore.buyTickets(BigNumber.from(currentPool.index))
          .then(() => {
            setTimeout(() => {
              navigate(0)
            }, 2000);
            resolve(true)
          })
          .catch(() => {
            reject('Something went wrong!')
          })
          .finally(() => {
            setBuyLoading(false)
          })
      })

      toast.promise(promise, {
        success: { title: 'Success', description: 'The ticket has been successfully purchased', position: "top-right", },
        error: { title: 'Error', description: 'Something wrong', position: "top-right", duration: 2000 },
        loading: { title: 'Pending', description: 'Waiting for transaction confirmation...', position: "top-right", },
      })
    } catch (e) {
      alert("There are not enough coins in your account");
      console.log(e);
    }
  }

  const handleCancelBuy = async (currentPool: PoolsType) => {
    setBuyLoading(true)
    try {
      let promise = new Promise(async (resolve, reject) => {
        await myStore.fundCancelBuy(BigNumber.from(currentPool.index), BigNumber.from(1))
          .then(() => {
            setTimeout(() => {
              navigate(0)
            }, 2000);
            resolve(true)
          })
          .catch((err) => {
            console.log(err);

            reject('Something went wrong!')
          })
          .finally(() => {
            setBuyLoading(false)
          })
      })

      toast.promise(promise, {
        success: { title: 'Success', description: 'The ticket has been successfully purchased', position: "top-right", },
        error: { title: 'Error', description: 'Something wrong', position: "top-right", duration: 2000 },
        loading: { title: 'Pending', description: 'Waiting for transaction confirmation...', position: "top-right", },
      })
    } catch (e) {
      alert("There are not enough coins in your account");
      console.log(e);
    }
  }

  const handleSell = async (currentPool: PoolsType) => {
    try {
      let promise = new Promise(async (resolve, reject) => {
        await myStore.sellTickets(BigNumber.from(currentPool.index))
          .then(() => {
            setTimeout(() => {
              navigate(0)
            }, 2000);
            resolve(true)
          })
          .catch(() => {
            reject('Something went wrong!')
          })
          .finally(() => {
            setBuyLoading(false)
          })
      })

      toast.promise(promise, {
        success: { title: 'Success', description: 'The ticket has successfully sold', position: "top-right", },
        error: { title: 'Error', description: 'Something wrong', position: "top-right", duration: 2000 },
        loading: { title: 'Pending', description: 'Waiting for transaction confirmation...', position: "top-right", },
      })
    } catch (e) {
      console.log(e);
    }
  }


  const handleCancelSell = async (currentPool: PoolsType) => {
    try {
      let promise = new Promise(async (resolve, reject) => {
        await myStore.fundCancelSell(BigNumber.from(currentPool.index), BigNumber.from(1))
          .then(() => {
            resolve(true)
          })
          .catch(() => {
            reject('Something went wrong!')
          })
          .finally(() => {
            setBuyLoading(false)
          })
      })

      toast.promise(promise, {
        success: { title: 'Success', description: 'The ticket has successfully sold', position: "top-right", },
        error: { title: 'Error', description: 'Something wrong', position: "top-right", duration: 2000 },
        loading: { title: 'Pending', description: 'Waiting for transaction confirmation...', position: "top-right", },
      })
    } catch (e) {
      console.log(e);
    }
  }


  return (
    <div className="background-image-yellow w-screen">
      <div className="wrapper-content ">
        <div className="flex flex-col lg:md:gap-12 gap-8 justify-between ">

          <div className='flex flex-col gap-4'>
            <h1 className="font-orbitron lg:md:w-2/3 w-full text-yellow lg:md:text-8xl sm:text-6xl text-[40px] leading-10 font-extrabold">PAC Staking 10000</h1>
            <div className="lg:md:w-1/2 w-full sm:w-4/5 font-medium lg:md:text-3xl sm:text-xl text-base text-white">
              <p>
                1 ticket = 10000 $PAC.</p>
              Buy tickets and invest $PAC in general pool and get profit from invested funds.  Sell and buy tickets from other participants at any time.
            </div>
          </div>
          <div className="lg:md:mt-14 sm:mt-7 ">
            <div className=" flex lg:md:flex-row flex-col lg:md:gap-36 gap-8 justify-between">
              <div className="flex flex-col lg:md:gap-8 gap-4">
                <div className="flex flex-row lg:text-5xl md:text-4xl sm:text-3xl text-2xl text-white font-orbitron font-bold">Total Tickets</div>
                <div className="flex flex-row lg:text-8xl md:text-7xl sm:text-6xl text-[45px] leading-10 text-yellow font-orbitron font-extrabold">{isLoading ? <Loader /> : `${total_tickets} tickets`}</div>
              </div>
              <div className="flex flex-col lg:md:gap-8 gap-4">
                <div className="flex flex-row lg:text-5xl md:text-4xl sm:text-3xl text-2xl text-white font-orbitron font-bold">Available tickets</div>
                <div className="flex flex-row lg:text-8xl md:text-7xl sm:text-6xl text-[45px] leading-10 text-yellow font-orbitron font-extrabold">{isLoading ? <Loader /> : `${available_tickets} tickets`}</div>
              </div>
            </div>
            <div className={`flex lg:md:sm:flex-row flex-col gap-6 justify-between lg:md:sm:items-center mt-20`}>
              <div className="lg:text-8xl md:text-6xl text-[40px] text-yellow font-extrabold font-orbitron">Pool</div>
              {
                (poolStatus == 'fulfilled') &&
                <div className="flex flex-col gap-2 self-end items-end">
                  <div className="lg:md:sm:text-2xl text-xl text-white font-orbitron">Your tickets at all</div>
                  <div className="lg:md:sm:text-[32px] text-2xl text-yellow font-orbitron">{all_user_tickets} tickets</div>
                </div>}
            </div>
            {
              (poolStatus == 'fulfilled') &&
              <div className={`lg:md:mt-14 sm:mt-10 mt-8 lg:md:gap-8 sm:gap-6 gap-4 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 `}>
                {
                  pools && [...pools].sort((a, b) => a.index - b.index).map((item) => {
                    return (
                      <div key={item.index} className="w-full bg-lightGray h-full min-h-min rounded-3xl">
                        <div className="lg:md:p-6 p-4 gap-14">
                          <div className="flex flex-col lg:md:gap-8 sm:gap-7 gap-6">
                            <div className="lg:md:text-[32px] text-2xl text-yellow font-orbitron font-bold">Pool #{item.index + 1}</div>
                            <div className="flex flex-col">
                              <div className="flex flex-row justify-between text-white lg:md:text-2xl sm:text-xl text-base font-orbitron font-bold">
                                <ul className="flex flex-col gap-3 w-full">
                                  <li className='flex flex-row justify-between items-center '>
                                    <span className='lg:md:text-2xl sm:text-xl text-base'>
                                      Your tickets
                                    </span>
                                    <span className='lg:md:text-2xl sm:text-lg text-sm'>
                                      {item.user_tickets}
                                    </span>
                                  </li>
                                  <li className='flex flex-row justify-between items-center'>
                                    <span className='lg:md:text-2xl sm:text-xl text-base'>
                                      Available tickets
                                    </span>
                                    <span className='lg:md:text-2xl sm:text-lg text-sm'>
                                      {item.available_tickets}
                                    </span>
                                  </li>
                                  <li className='flex flex-row justify-between items-center'>
                                    <span className='lg:md:text-2xl sm:text-xl text-base'>
                                      Sales requests
                                    </span>
                                    <span className='lg:md:text-2xl sm:text-lg text-sm'>
                                      {item.sales_requests}
                                    </span>
                                  </li>
                                  <li className='flex flex-row justify-between items-center'>
                                    <span className='lg:md:text-2xl sm:text-xl text-base'>
                                      Buy requests
                                    </span>
                                    <span className='lg:md:text-2xl sm:text-lg text-sm'>
                                      {item.purchase_requests}
                                    </span>
                                  </li>
                                </ul>

                              </div>
                            </div>
                            <button className="text-lg lg:md:mt-5 sm:mt-1 mt-0 text-yellow font-orbitron font-semibold bg-black rounded-3xl px-6 py-3 12" onClick={() => {
                              setCurrentPool(item)
                              setOpenModal(true)
                            }}>Actions</button>
                          </div>

                        </div>
                      </div>
                    )
                  })
                }
              </div>
            }
            {
              (poolStatus == 'pending') &&
              <div className='pt-10'>
                <Loader />

              </div>
            }
            {
              (poolStatus == 'Uninitialized') &&
              <div className="bg-lightGray rounded-[30px] flex flex-col items-center lg:md:mt-10 mt-8 gap-10 px-6 pt-16 pb-12 max-[920px]:pt-8 max-[920px]:pb-6">
                <div className="flex flex-col items-center gap-5">
                  <div className="font-orbitron text-white text-center text-[28px] leading-[35px] max-[920px]:text-[18px] max-[920px]:leading-[23px]">
                    Connect your wallet to see the pools
                  </div>
                </div>
                <div>
                  <Wallet padding='p-2' />
                </div>
              </div>
            }
            {currentPool &&
              <Modal show={openModal} theme={Theme} size="md" onClose={() => setOpenModal(false)} popup className='bg-black opacity-1 bg-opacity-1 rounded-2xl'>
                <Modal.Header className='bg-lightGray'>
                  Pool #{currentPool.index + 1}
                </Modal.Header>
                <Modal.Body className='bg-lightGray rounded-b-2xl'>
                  <div className="text-center text-white">
                    <div className="flex flex-col justify-between text-white gap-5 text-2xl w-full font-orbitron font-bold">
                      <ul className="flex flex-col gap-4 w-full">
                        <li className='flex flex-row justify-between w-full'>
                          <span className='lg:md:text-2xl sm:text-xl text-base'>
                            Your tickets
                          </span>
                          <span className='lg:md:text-2xl sm:text-lg text-sm'>
                            {currentPool.user_tickets}
                          </span>
                        </li>
                        <li className='flex flex-row justify-between'>
                          <span className='lg:md:text-2xl sm:text-xl text-base'>
                            Available tickets
                          </span>
                          <span className='lg:md:text-2xl sm:text-lg text-sm'>
                            {currentPool.available_tickets}
                          </span>
                        </li>
                        <li className='flex flex-row gap-3'>
                          {
                            buyLoading ?
                              <button
                                type='submit'
                                className={`text-white 'button_loading'} w-1/2 bg-customBlack hover:bg-customBlackHover p-3 rounded-xl text-base disabled:opacity-30`}
                                disabled={currentPool.available_tickets == 0 ? true : false}
                              >
                                <ButtonLoading />
                              </button>

                              :
                              <button
                                type='submit'
                                className={`text-white 'button_loading'} w-1/2 bg-customBlack hover:bg-customBlackHover p-3 rounded-xl text-base disabled:opacity-30`}
                                onClick={() => handleBuy(currentPool)}
                              >
                                Buy
                              </button>
                          }
                          {
                            sellLoading ?
                              <button
                                type='submit'
                                className={`text-white 'button_loading'} w-1/2 bg-customBlack hover:bg-customBlackHover p-3 rounded-xl text-base disabled:opacity-30`}
                                disabled={currentPool.user_tickets == 0 ? true : false}
                              >
                                <ButtonLoading />
                              </button>
                              :
                              <button
                                className={`text-base text-black w-1/2 bg-[#898989] hover:bg-lightGrayHover p-3 rounded-xl disabled:opacity-30`}
                                onClick={() => handleSell(currentPool)}
                                disabled={currentPool.user_tickets == 0 ? true : false}
                              >
                                Sell
                              </button>
                          }
                        </li>
                      </ul>
                      <ul className="flex flex-col gap-4 w-full">
                        <li className='flex flex-row justify-between w-full'>
                          <span className='lg:md:text-2xl sm:text-xl text-base'>
                            Sales requests
                          </span>
                          <span className='lg:md:text-2xl sm:text-lg text-sm'>
                            {currentPool.sales_requests}
                          </span>
                        </li>
                        <li className='flex flex-row justify-between'>
                          <span className='lg:md:text-2xl sm:text-xl text-base'>
                            Buy requests
                          </span>
                          <span className='lg:md:text-2xl sm:text-lg text-sm'>
                            {currentPool.purchase_requests}
                          </span>
                        </li>
                        <li className='flex flex-row gap-3'>
                          {
                            buyLoading ?
                              <button
                                type='submit'
                                className={`text-white 'button_loading'} w-1/2 bg-customBlack hover:bg-customBlackHover p-3 rounded-xl text-base disabled:opacity-30`}
                                disabled={currentPool.purchase_requests == 0 ? true : false}
                              >
                                <ButtonLoading />
                              </button>

                              :
                              <button
                                type='submit'
                                className={`text-white 'button_loading'} w-1/2 bg-customBlack hover:bg-customBlackHover p-3 rounded-xl text-base disabled:opacity-30`}
                                disabled={currentPool.user_on_buy == 0 ? true : false}
                                onClick={() => handleCancelBuy(currentPool)}
                              >
                                Cancel buy
                              </button>
                          }
                          {
                            sellLoading ?
                              <button
                                type='submit'
                                className={`text-white 'button_loading'} w-1/2 bg-customBlack hover:bg-customBlackHover p-3 rounded-xl text-base disabled:opacity-90`}
                                disabled={currentPool.sales_requests == 0 ? true : false}
                              >
                                <ButtonLoading />
                              </button>
                              :
                              <button
                                className={`text-base text-black w-1/2 bg-[#898989] hover:bg-lightGrayHover p-3 rounded-xl disabled:opacity-30`}
                                onClick={() => handleCancelSell(currentPool)}
                                disabled={currentPool.user_on_sell == 0 ? true : false}
                              >
                                Cancel sale
                              </button>
                          }
                        </li>
                      </ul>
                    </div>
                  </div>
                </Modal.Body>

              </Modal>
            }

          </div>
        </div>
      </div >

    </div >

  )


})

export default Pac10000
