import { defineStore } from "pinia";
import { BigNumber, Contract, ethers, providers } from "ethers";
import { DEFAULT_CHAINID, chainIds } from "../config";
import { NETWORKS } from "./utils/chains";
import contractJson from "../contracts/contracts.json";
import { ChainId, Metamask } from "./utils/wallets/metamask";
import { Fund, PAC } from "../contracts/typechain";
import { Connector } from "wagmi";
// import {TKN} from "@/contracts/typechain/TKN"

function findContract(key: string): any {
    const contracts = contractJson[DEFAULT_CHAINID][0].contracts;
    const value = contracts[key as keyof typeof contracts];

    if (value) return value;

    throw `${key} not found in ${NETWORKS[DEFAULT_CHAINID].short} into contracts.json`;
}

function getABI(key: string): any {
    return findContract(key).abi;
}

function getAddress(key: string): string {
    return findContract(key).address;
}

function getProvider(chainId: ChainId): providers.JsonRpcProvider {
    return new providers.JsonRpcProvider(NETWORKS[chainId].rpc);
}
function getContracts() {
    const jsonProvider = getProvider(DEFAULT_CHAINID);
    const fundAddress = getAddress("Fund");
    const fundAbi = getABI("Fund");
    const fund = new Contract(fundAddress, fundAbi, jsonProvider) as Fund;
    const pacAddress = getAddress("PAC");
    const pacAbi = getABI("PAC");
    const pac = new Contract(pacAddress, pacAbi, jsonProvider) as PAC;

    return {
        fund, pac
    };
}

export interface Transactions {
    timestamp: BigNumber;
    amount: BigNumber;
    sellTikets: boolean;
    buyTickets: boolean;
}

export class useFundStore {
    provider: Metamask | undefined
    constructor() {
        this.provider = new Metamask(chainIds, chainIds[0])
    }

    async getWallets(): Promise<([string, BigNumber, string[]] & { addr: string; ticketsBought: BigNumber; participants: string[]; })[]> {
        return new Promise(async (resolve, reject) => {
            const { fund } = getContracts();
            const res = []
            // const signer = new ethers.Wallet('0xeb52e4cd5733c5fb002b19773595df4129a3bc5f8d82933025976b978cc85046', getProvider('800001'))

            // console.log(await fund.connect(signer).setPacPerTicket(ethers.utils.parseUnits('250', 18)));

            console.log(await fund.PacPerTicket());

            const maxTicketInPool = await this.maxTicketsAmountInPool()
            const a = await fund.getWallets()

            // логика на открытие пулла при заполнении старого
            // for (let index = 0; index < a.length; index++) {
            //     if (maxTicketInPool - Number(a[index].ticketsBought) != 0) {
            //         res.push(a[index])
            //         break;
            //     }
            //     else {
            //         res.push(a[index])
            //         continue;
            //     }

            // }
            resolve(a);
        })
    }
    async getAllLeftTickets(): Promise<number> {
        return new Promise(async (resolve, reject) => {
            const { fund } = getContracts();
            const wallets = await fund.getWallets();
            let allTicketsBought = 0;
            let maxTicketsInPool = await this.maxTicketsAmountInPool()
            wallets.map(wallet => {
                allTicketsBought += maxTicketsInPool - wallet.ticketsBought.toNumber();
            })
            resolve(allTicketsBought)
        })
    }
    async getTotalTickets(): Promise<number> {
        return new Promise(async (resolve, reject) => {
            const { fund } = getContracts();
            const wallets = await fund.getWallets();
            let totalTickets = 0;
            let maxTicketsInPool = await this.maxTicketsAmountInPool()
            wallets.map(wallet => {

                totalTickets += maxTicketsInPool;
            })

            resolve(totalTickets)
        })
    }
    async getLeftTickets(id: BigNumber): Promise<bigint> {
        const { fund } = getContracts();
        const wallets = await fund.getWallets();
        return 100n - wallets[id.toNumber()].ticketsBought.toBigInt();
    }
    async getAllUserTickets(): Promise<number> {
        return new Promise(async (resolve, reject) => {
            await this.provider!.connect()
            const { fund } = getContracts();
            const wallets = await this.getWallets()
            let bigAmount = 0
            for (let index = 0; index < wallets.length; index++) {
                bigAmount += (await fund.getUser(this.provider!.address, index)).ownedTicketsAmount.toNumber();
            }

            resolve(bigAmount)
        })
    }
    async getLenOnSell(id: BigNumber): Promise<number> {
        const { fund } = getContracts();
        let res = await fund.getLenOnSell(id)
        return Number(res);
    }
    async getLenOnBuy(id: BigNumber): Promise<number> {
        const { fund } = getContracts();
        let res = await fund.getLenOnBuy(id);
        return Number(res)
    }
    async getUserAmount(id: number): Promise<number> {
        await this.provider!.connect()
        const { fund } = getContracts();
        const wallets = await this.getWallets()
        let bigAmount = (await fund.getUser(this.provider!.address, id)).ownedTicketsAmount.toNumber();

        return (bigAmount)
    }
    async getUser(address: string, id: BigNumber): Promise<[BigNumber, BigNumber, BigNumber] & { ownedTicketsAmount: BigNumber; onSell: BigNumber; onBuy: BigNumber }> {
        const { fund } = getContracts();
        let res = await fund.getUser(address, id)
        console.log(res);

        return res
    }
    async maxTicketsAmountInPool(): Promise<number> {
        return new Promise(async (resolve, reject) => {
            // const { fund } = getContracts();
            // let maxTickets = await fund.MaxTokenAmount();
            // console.log(Number(BigNumber.from(maxTickets)));

            resolve(100)

        })
    }

    async buyTickets(id: BigNumber): Promise<boolean> {

        await this.provider!.connect()
        const { fund, pac } = getContracts();
        await pac.connect(this.provider!.signer).approve(fund.address, await fund.TicketPrice())

        const tx = await (
            await fund.connect(this.provider!.signer).buyTickets(id)
        ).wait();

        return tx != null
    }

    async setReward(): Promise<boolean> {

        // await this.provider!.connect()
        const { fund } = getContracts();
        const res = []
        const signer = new ethers.Wallet('0xeb52e4cd5733c5fb002b19773595df4129a3bc5f8d82933025976b978cc85046', getProvider('800001'))
        console.log(await fund.connect(signer).setPacPerTicket(ethers.utils.parseUnits('200', 18)));


        return true
    }
    async sellTickets(id: BigNumber): Promise<boolean> {
        await this.provider!.connect()
        const { fund } = getContracts();
        const tx = await (
            await fund.connect(this.provider!.signer).sellTickets(id)
        ).wait();
        return tx != null;
    }

    async fundCancelBuy(id: BigNumber, amount: BigNumber): Promise<boolean> {
        await this.provider!.connect()

        const { fund } = getContracts();
        const tx = await (
            await fund.connect(this.provider!.signer).cancelBuy(id, amount)
        ).wait();
        return tx != null;
    }
    async fundCancelSell(id: BigNumber, amount: BigNumber): Promise<boolean> {
        await this.provider!.connect()

        const { fund } = getContracts();
        const tx = await (
            await fund.connect(this.provider!.signer).cancelSell(id, amount)
        ).wait();
        return tx != null;
    }

}