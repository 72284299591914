import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import { store } from "./app/store"
import "./index.css"
import "./index.css"
import {
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom"

import Layout from "./Layout"

import Page404 from "./helpers/Page404"
import { ChakraProvider } from "@chakra-ui/react"

// wagmi
import { createClient, WagmiConfig, configureChains, Chain } from "wagmi"
import { bsc, bscTestnet } from "@wagmi/core/chains"
import { publicProvider } from "wagmi/providers/public"
import Pac10000 from "./features/staking/pac/Pac10000"


const octaSpace = {
  id: 800001,
  name: "Octa Space",
  network: "octa_space",
  nativeCurrency: {
    decimals: 18,
    name: "OCTA",
    symbol: "OCTA",
  },
  rpcUrls: {
    default: { http: ["https://rpc.octa.space"] },
    public: { http: ["https://rpc.octa.space"] },
  },
  blockExplorers: {
    etherscan: { name: "OctaScan", url: "https://explorer.octa.space" },
    default: { name: "OctaScan", url: "https://explorer.octa.space" },
  },
  testnet: false,
} as Chain

const redev2 = {
  id: 1972,
  name: "REDEV2",
  network: "redev2",
  nativeCurrency: {
    decimals: 18,
    name: "REDEV2",
    symbol: "REDEV2",
  },
  rpcUrls: {
    default: { http: ["https://rpc2.redecoin.eu/"] },
    public: { http: ["https://rpc2.redecoin.eu/"] },
  },
  blockExplorers: {
    etherscan: { name: "RedeScan", url: "https://explorer3.redecoin.eu" },
    default: { name: "RedeScan", url: "https://explorer3.redecoin.eu" },
  },
  testnet: false,
} as Chain

const { provider, webSocketProvider } = configureChains(
  [bsc, bscTestnet, octaSpace, redev2],
  [publicProvider()],
)

const client = createClient({
  provider,
  webSocketProvider,
  autoConnect: false,
})

// etc

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Pac10000 />,
      },
    ],
  },
  {
    path: "*",
    element: <Page404 />,
  },
])


ReactDOM.createRoot(document.getElementById("root")!).render(
  <ChakraProvider resetCSS={true} disableGlobalStyle={true}>
    <Provider store={store}>
      <WagmiConfig client={client}>
        <RouterProvider router={router} />
      </WagmiConfig>
    </Provider>
  </ChakraProvider>,
)